import React, { useEffect, useState } from 'react';
import ProductField from './ProductField';


const AddProduct = () => {
     return (
        <div className='mx-auto p-2 mt-8'>
            <h1 className='text-md text-center bg-red-600 p-3 text-white rounded-xl'>Add And Manage Product</h1>
            <ProductField></ProductField>
        </div>
    );
};

export default AddProduct;