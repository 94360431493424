import { createSlice } from "@reduxjs/toolkit"



const initialState = {
    completedService: [],
    filterBy:'Today',


}

const serviceReportSlice = createSlice({
    name: 'serviceReport',
    initialState,
    reducers: {
        getAllCompletedService: (state, action) => {
            state.completedService = action.payload;
        },
        filterServices: (state, action) => {
            state.filterBy = action.payload;
        },
    },
})


export const {getAllCompletedService,filterServices } = serviceReportSlice.actions;
export default serviceReportSlice.reducer;