import { createSlice } from "@reduxjs/toolkit"


const initialState={
   user:[],
   openClose:{},
}

const userSlice= createSlice({
    name: 'info',
    initialState,
    reducers:{
        getUser:(state, action)=>{
            state.user=action.payload
        },
        getOpenClose:(state, action)=>{
            state.openClose=action.payload
        },
    }
});

export const {getUser,getOpenClose}= userSlice.actions;
export default userSlice.reducer;