import React, { useEffect, useState } from 'react';
import IncomeHeadField from './IncomeHeadField';


const AddIncomeHead = () => {
     return (
        <div className='mx-auto w-full p-2 mt-8'>
            <h1 className='text-md text-center bg-red-600 p-3 text-white rounded-xl'>Add A New Income Head</h1>
            <IncomeHeadField></IncomeHeadField>
        </div>
    );
};

export default AddIncomeHead;