import React, { useEffect, useState } from 'react';
import CustomerField from './CustomerField';


const AddMainCustomer = () => {
     return (
        <div className='mx-auto p-2 mt-8'>
            <h1 className='text-md text-center bg-red-600 p-3 text-white rounded-xl'>Add A Customer</h1>
            <CustomerField></CustomerField>
        </div>
    );
};

export default AddMainCustomer;