import React from 'react';

const ImageModal = ({ selectedImage, onClose }) => {
    console.log(selectedImage);
    const noEdit = () => {
        window.location.reload(false);
    }
    return (
        <div>
            <input type="checkbox" id="image-modal" class="modal-toggle" />
            <div class="modal mt-12">
                <div class="modal-box relative text-xs">
                    <label for="image-modal"  class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <img src={selectedImage} className='w-50 mx-auto' alt="Large Image" />
                </div>
            </div>
        </div >
    );
};

export default ImageModal;


