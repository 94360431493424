import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { editSupplier } from '../../../../features/InfoSlice/infoSlice';
import Loader from '../../../Shared/Loader';



const LoanAccountField = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [loanAccounts, setLoanAccounts] = useState([]);
    const [updateLoanAccount, setUpdateLoanAccount] = useState(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const accountCode = (loanAccounts?.length + 1).toString().padStart(4, '0');

    useEffect(() => {
        fetch('https://16ana.net/saad_tiles/getloanaccounts')
            .then(res => res.json())
            .then(data => {
                setLoanAccounts(data);
                setLoading(false)
            })
    }, [loanAccounts, setLoanAccounts, updateLoanAccount, setUpdateLoanAccount, dispatch])

    if (loading) {
        return <Loader />
    }

    const onSubmit = data => {
        const user = userAc?.email.split('@')[0];
        fetch('https://16ana.net/saad_tiles/addloanaccount', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({user,...data}),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Loan Account Added')
                reset();
                setTimeout(() => { window.location.reload(); }, 3000);
            })

    }

    const handleDeleteLoanAccount = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://16ana.net/saad_tiles/getloanaccount/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdateLoanAccount(null);
                    setTimeout(() => { window.location.reload(); }, 2000);
                })
        }
    };

    const handleUpdateLoanAccount = (loanAccount) => {

        // fetch(`https://16ana.net/saad_tiles/getloanAccounts/${Loan Account._id}`)
        //     .then(res => res.json())
        //     .then(data => {
        //         dispatch(editSupplier(data))
        //     })
        setUpdateLoanAccount(loanAccount);
    }

    const handleApprove=(loanAccount)=>{
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://16ana.net/saad_tiles/getloanaccount/${loanAccount._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({approvedBy})
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Loan Account Approved');
                // window.location.reload(false)
            })
    }

    return (
        <div className='shadow-2xl px-2 pb-5 my-5 text-xs'>
            <form onSubmit={handleSubmit(onSubmit)} className='container grid grid-cols-1 lg:grid-cols-3 gap-1 justify-center items-center'>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Loan Account Code</span>
                    </label>
                    <input {...register("code")} value={accountCode} type="text" placeholder="Type Loan Account Code Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Loan Account Name</span>
                    </label>
                    <input {...register("name")} type="text" placeholder="Type Loan Account Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Address</span>
                    </label>
                    <input {...register("address")} type="text" placeholder="Type Address Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Phone No</span>
                    </label>
                    <input {...register("phone")} type="text" placeholder="Type Phone No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Opening Balance</span>
                    </label>
                    <input {...register("opening")} type="number" placeholder="Opening Balance" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Status</span>
                    </label>
                    <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                        <option selected>Active</option>
                        <option>Inactive</option>
                    </select>
                </div>
                <input disabled={!userAc?.addLoanAccount} type="submit" value='Add Loan Account' className='btn w-full lg:w-60 bg-red-600 text-white mt-2 lg:mt-9' />
            </form>

            {
                loanAccounts.length ? <div class="overflow-x-auto mt-8">
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL</th>
                                <th className='bg-red-600 text-white normal-case'>Loan Account Code</th>
                                <th className='bg-red-600 text-white normal-case'>Loan Account Name</th>
                                <th className='bg-red-600 text-white normal-case'>Address</th>
                                <th className='bg-red-600 text-white normal-case'>Phone No</th>
                                <th className='bg-red-600 text-white normal-case'>Opening</th>
                                <th className='bg-red-600 text-white normal-case'>Status</th>
                                <th className='bg-red-600 text-white normal-case'>Added By</th>
                                <th className='bg-red-600 text-white normal-case'>Approved By</th>
                                <th className='bg-red-600 text-white normal-case'>Edit</th>
                                <th className='bg-red-600 text-white normal-case'>Delete</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                loanAccounts.map((loanAccount, index) => <tr className='hover'>
                                    <td>{index + 1}</td>
                                    <td>{loanAccount?.code}</td>
                                    <td>{loanAccount.name}</td>
                                    <td>{loanAccount.address}</td>
                                    <td>{loanAccount.phone}</td>
                                    <td>{loanAccount.opening}</td>
                                    <td> <button className={`btn btn-xs normal-case ${loanAccount.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{loanAccount?.status}</button> </td>
                                    <td><button className='btn btn-xs btn-primary text-white normal-case'>{loanAccount?.user ? loanAccount.user : "User"}</button></td>
                                    {userAc.approvalLoanAccount? <td>{loanAccount?.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{loanAccount?.approvedBy}</button>: <button onClick={()=>handleApprove(loanAccount)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td>:
                                   <td>{loanAccount.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{loanAccount.approvedBy}</button>:<button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                    {userAc?.editLoanAccount && <td><button><label for="update-modal" onClick={() => handleUpdateLoanAccount(loanAccount)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                    {userAc?.deleteLoanAccount && <td><button onClick={() => handleDeleteLoanAccount(loanAccount._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div> : ""
            }
            {
                updateLoanAccount && <UpdateModal setUpdateLoanAccount={setUpdateLoanAccount} updateLoanAccount={updateLoanAccount}></UpdateModal>
            }
        </div>
    );
};

export default LoanAccountField;