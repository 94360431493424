import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Outlet } from 'react-router-dom';
import auth from '../Firebase/Firebase';
import Footer from '../pages/Shared/Footer';
import Navbar from '../pages/Shared/Navbar';

const Main = () => {
    // const [windowWidth, setWindowWidth] = useState('')
    // const [windowHeight, setWindowHeight] = useState('')

    // const handleResize = () => {
    //     document.body.style.zoom="67%";
    //     document.body.style.height="100%"
    
        
    //   }

    //   onLoad={handleResize} style={{ width: `${windowWidth * 0.67}px`, height: `${windowHeight * 0.67}px`, }}
    return (
        <div>
            <Navbar />
            <Outlet />
            <Footer />
        </div>
    );
};

export default Main;