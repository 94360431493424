import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useQuery } from 'react-query';
import Loader from '../../../Shared/Loader';

const TopSellingLessSelling = () => {
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [sales, setSales] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [sort, setSort] = useState('Top Selling');
    const [isStock, setIsStock] = useState(true);
    const [returnSales, setReturnSales] = useState([]);

    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    const sortRef = useRef('');
    const handleSort = () => {
        const sortValue = sortRef.current.value;
        if (sortValue === "Top Selling") {
            setSort("Top Selling")
        } else {
            setSort("Less Selling")
        }
    }

    useEffect(() => {
        const url = `https://16ana.net/saad_tiles/getsalesproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setSales(data);
                console.log(data);
            })
    }, []);

    useEffect(() => {
        const url = `https://16ana.net/saad_tiles/getpurchaseproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setPurchases(data);
                console.log(data);
            })
    }, []);

    useEffect(() => {
        const url = `https://16ana.net/saad_tiles/getreturnsalesproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setReturnSales(data);
            })
    }, []);

    const { data: products, isLoading } = useQuery(['products'], () => fetch('https://16ana.net/saad_tiles/getproducts').then(res => res.json()));



    // Stock Report

    // Sales Quantity

    let array = [];

    for (let i = 0; i < products?.length; i++) {
        const filtered = sales.filter(sale => sale?.code === products[i]?.code);
        array.push(filtered);
    }

    let array2 = [];
    for (let i = 0; i < array?.length; i++) {
        const salesQuantity = array[i]?.map(a => parseInt(a.quantity));
        const totalSales = salesQuantity?.reduce((a, b) => parseInt(a) + parseInt(b), 0);
        array2.push(totalSales);
    }

    // Purchase Quantity

    let array3 = [];
    for (let i = 0; i < products?.length; i++) {
        const filtered = purchases.filter(purchase => purchase.code === products[i].code);
        array3.push(filtered);
    }

    let array4 = [];

    for (let i = 0; i < array?.length; i++) {
        let loanReturnAccounts = array3[i]?.map(a => parseInt(a.quantity));
        const totalLoanReturn = loanReturnAccounts?.reduce((a, b) => parseInt(a) + parseInt(b), 0)
        array4.push(totalLoanReturn)
    }

    // Return Quantity
    let array5 = [];
    for (let i = 0; i < products?.length; i++) {
        const filtered = returnSales.filter(rs => rs.code === products[i].code);
        array5.push(filtered);
    }

    let array6 = [];

    for (let i = 0; i < array?.length; i++) {
        let loanReturnAccounts = array5[i]?.map(a => parseFloat(a.returnQuantity));
        const totalLoanReturn = loanReturnAccounts?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        array6.push(totalLoanReturn)
    }


    // Joining Balance To data

    let data = [];
    for (let i = 0; i < products?.length; i++) {
        const product = ({
            _id: products[i]._id, name: products[i]?.name,
            code: products[i].code, category: products[i].category,
            brand: products[i].brand, minimum: products[i].minimum,
            origin: products[i].origin, unit: products[i].unit, status: products[i].status,
            purchase: products[i].purchase, sell: products[i].sell, totalSale: array2[i],
            totalPurchase: array4[i], totalReturn: array6[i], opening: products[i].opening,
            stock: (products[i]?.opening) + array4[i] + array6[i] - array2[i], stockCost: (products[i].opening
                + array4[i] + array6[i] - array2[i]) * products[i].purchase,
        });


        data.push(product);
    }

    const sorted = sort === "Less Selling" ? data?.sort(function (a, b) {
        return (a?.totalSale > b?.totalSale) ? 1 : ((a?.totalSale < b?.totalSale) ? -1 : 0);
    }) : data?.sort(function (a, b) {
        return (a?.totalSale < b?.totalSale) ? 1 : ((a?.totalSale > b?.totalSale) ? -1 : 0);
    });

    if (isLoading) {
        return <Loader></Loader>
    }

    const handleSearchResult = (event) => {
        const searchText = event.target.value;
        const match = sorted.filter(product => product.name.includes(searchText) || product.name.toLowerCase().includes(searchText)
            || product.name.toUpperCase().includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }

    const totalBalance = isSearching ? searchResult.reduce((total, currentValue) => total + parseFloat(currentValue.stockCost), 0) : data.reduce((total, currentValue) => total + parseFloat(currentValue.stockCost), 0);
    const totalSellingBalance = isSearching ? searchResult.reduce((total, currentValue) => total + parseFloat(currentValue?.totalSale * currentValue?.sell), 0) : data.reduce((total, currentValue) => total + parseFloat(currentValue?.totalSale * currentValue?.sell), 0);

    const handleFilteredData = e => {
        e.preventDefault();
        const startDate = e.target.startDate.value;
        const endDate = e.target.endDate.value;
        const sortBy = e.target.sort.value;
        setSort(sortBy);
        const url = `https://16ana.net/saad_tiles/getsalesproductswithfilter?startDate=${startDate}&endDate=${endDate}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setSales(data)
                setIsStock(false)
            })
        const url2 = `https://16ana.net/saad_tiles/getpurchaseproductswithfilter?startDate=${startDate}&endDate=${endDate}`
        fetch(url2)
            .then(res => res.json())
            .then(data => {
                setPurchases(data)
                setIsStock(false)
            })
    }


    return (
        <div className='mx-2'>
            <h1 className='text-center text-lg mt-8'>Top Selling Less Selling Products List</h1>
            <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center'>
                <form onSubmit={handleFilteredData} className='mt-12 flex flex-col lg:flex-row justify-center items-right'>
                    <div class="form-control w-full lg:w-40 mx-auto lg:w-40 max-w-xs lg:mr-2">
                        <label class="label">
                            <span class="label-text">Starting Date</span>
                        </label>
                        <input name='startDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full lg:w-40 mx-auto lg:w-40 max-w-xs lg:mr-2">
                        <label class="label">
                            <span class="label-text">End Date</span>
                        </label>
                        <input name='endDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                    </div>
                    <div class={`form-control w-full lg:w-40 mx-auto max-w-xs lg:mr-2`}>
                        <label class="label">
                            <span class="label-text">Short By</span>
                        </label>
                        <select style={{ padding: '' }} {...register("sort")} defaultValue='' class="rounded-lg text-black text-xs select select-bordered w-full max-w-xs" required>
                            <option selected>Top Selling</option>
                            <option>Less Selling</option>
                        </select>
                    </div>

                    <input type='submit' value='Search' className='btn btn-md btn-error w-80 mx-auto lg:w-20 text-white mt-4 lg:mt-9 lg:ml-2' />
                </form>
            </div>

            <div className='flex justify-between mt-16'>
                <div className={`form-control w-40 max-w-xs mt-3 lg:mt-0 lg:mr-2`}>
                    <label class="label">
                        <span class="label-text">Short By</span>
                    </label>
                    <select ref={sortRef} style={{ padding: '' }} onChange={handleSort} defaultValue='' class="rounded-lg text-black text-xs select select-bordered w-full max-w-xs" required>
                        <option selected>Top Selling</option>
                        <option>Less Selling</option>
                    </select>
                </div>
                <div>
                    <div className='text-right'>
                        <ReactHtmlTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-error text-white btn-xs mt-2 lg:mt-0"
                            table="table-to-xls"
                            filename={`Stock Sheet`}
                            sheet={`stock-sheet`}
                            buttonText="Export to exel" />
                        {/* <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button> */}
                    </div>
                    <div className='flex justify-end items-center my-4'>
                        <div class="form-control lg:w-60 w-52">
                            <input onChange={handleSearchResult} type="text" placeholder="Search Product" class="input text-xs input-bordered bg-white input-error w-full max-w-xs" />
                        </div>
                    </div>
                </div>

            </div>
            <div class="overflow-x-auto mt-8">
                <table id="table-to-xls" class="table w-1/2 mx-auto text-xs mb-20">
                    <thead>
                        <tr className='text-center'>
                            <th className='bg-red-600 text-white normal-case'>SL</th>
                            <th className='bg-red-600 text-white normal-case'>Product Code</th>
                            <th className='bg-red-600 text-white normal-case'>Product Name</th>
                            <th className='bg-red-600 text-white normal-case'>Cost Price</th>
                            <th className='bg-red-600 text-white normal-case'>Sales Price</th>
                            {/* <th className='bg-red-600 text-white normal-case'>Opening Balance</th> */}
                            <th className='bg-red-600 text-white normal-case'>Total Sales Quantity</th>
                            <th className='bg-red-600 text-white normal-case'>Total Sales Amount</th>
                            {/* <th className='bg-red-600 text-white normal-case'>Total Purchase</th> */}
                            {isStock && <th className='bg-red-600 text-white normal-case'>Current Stock</th>}
                            {isStock && <th className='bg-red-600 text-white normal-case'>Cost Balance</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isSearching ? searchResult.map((product, index) => <tr className='text-center'>
                                <td>{index + 1}</td>
                                <td>{product?.code}</td>
                                <td>{product?.name}</td>
                                <td>{product?.purchase}</td>
                                <td>{product?.sell}</td>
                                {/* <td className='text-blue-800'>{product?.opening}</td> */}
                                <td className='text-green-600'>{product?.totalSale - product?.totalReturn}</td>
                                <td className='text-green-600'>{(product?.totalSale - product?.totalReturn) * product?.sell}</td>
                                {/* <td className='text-yellow-700'>{product?.totalPurchase}</td> */}
                                {isStock && <td className='text-red-600 font-bold'>{product?.stock}</td>}
                                {isStock && <td>{(product.stockCost).toFixed(2)}</td>}
                            </tr>) : sorted.map((product, index) => <tr className='text-center'>
                                <td>{index + 1}</td>
                                <td>{product?.code}</td>
                                <td>{product?.name}</td>
                                <td>{product?.purchase}</td>
                                <td>{product?.sell}</td>
                                {/* <td className='text-blue-800'>{product?.opening}</td> */}
                                <td className='text-green-600'>{product?.totalSale - product?.totalReturn}</td>
                                <td className='text-green-600'>{(product?.totalSale - product?.totalReturn) * product?.sell}</td>
                                {/* <td className='text-yellow-700'>{product?.totalPurchase}</td> */}
                                {isStock && <td className='text-red-600 font-bold'>{product?.stock}</td>}
                                {isStock && <td>{(product.stockCost).toFixed(2)}</td>}
                            </tr>)
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'>Total</th>
                            <th className='bg-red-600 text-white normal-case'>{totalSellingBalance}</th>
                            {isStock && <th className='bg-red-600 text-white normal-case'></th>}
                            {isStock && <th className='bg-red-600 text-white normal-case'>{(totalBalance)?.toFixed(2)}</th>}
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

export default TopSellingLessSelling;