import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToProductList, addToUpdateSales } from '../../../../../../features/SalesSlice/SalesSlice';


const UpdateModal = ({ updateProduct, setUpdateProduct }) => {
    const products = useSelector((state) => state.salesInvoice.products);
    const purchaseProducts = useSelector((state) => state.salesInvoice.updatePurchaseProducts);
    const [total, setTotal] = useState(false);
    const [changingRate, setChangingRate] = useState(false);
    const [quantity, setQuantity] = useState();
    const [defaultQuantity, setDefaultQuantity] = useState();
    const [salesRate, setSalesRate] = useState();
    const [addError, setAddEroor] = useState();
    const [qytError, setQytError] = useState(false);
    const [costTk, setCostTk] = useState(0);
    const [costHistory, setCostHistory] = useState([])
    const dispatch = useDispatch();


    console.log(costTk);
    console.log(purchaseProducts);
    console.log(costHistory);


    useEffect(() => {
        const defaultCostTkTimeout = setTimeout(() => {
            if (purchaseProducts.length > 0) {
                setCostTk(purchaseProducts[0]?.rate);
            }
        }, 200);
        return () => clearTimeout(defaultCostTkTimeout);
    }, [purchaseProducts]);

    const codeRef = useRef('');
    const nameRef = useRef('');
    const quantityRef = useRef();
    const rateRef = useRef();
    const totalRef = useRef();


    const addProduct = () => {
        const code = codeRef.current.innerText;
        const name = nameRef.current.innerText;
        const quantity = quantityRef.current.value;
        const rate = rateRef.current.value;
        const total = totalRef.current.value;
        const cost = parseFloat(quantityRef.current.value) * parseFloat(costTk)

        if (!isNaN(costTk)) {
            // const cost = parseFloat(quantityRef.current.value) * parseFloat(costTk);
            dispatch(addToUpdateSales({ code, name, quantity, rate, total, cost: costTk, costHistory }));
            setUpdateProduct(null);
        }

    }

    // const handleTotal = (event) => {
    //     const quantity = parseFloat(event?.target?.value);
    //     setQuantity(quantity);
    //     setTotal(true);

    //     if ((event?.target?.value).slice(0, 1) === '.') {
    //         setQytError(true)
    //     } else {
    //         setQytError(false)
    //     }
    // }

    const handleTotal = (event, productStock) => {
        const sellingQuantity = parseFloat(event?.target?.value);
        setQuantity(sellingQuantity);
        setTotal(true);

        if ((event?.target?.value).slice(0, 1) === '.') {
            setQytError(true);
        } else {
            setQytError(false);
        }

        let remainingQuantity = sellingQuantity;
        let totalCost = 0;
        const newCostHistory = [];

        for (const purchase of purchaseProducts) {
            const { rate, quantity, lot } = purchase;

            if (remainingQuantity > quantity) {
                totalCost += quantity * rate;
                remainingQuantity -= quantity;
                newCostHistory.push({ lot, quantity, rate });
            } else {
                totalCost += remainingQuantity * rate;
                newCostHistory.push({ lot, quantity: remainingQuantity, rate });
                setCostTk(totalCost);
                setCostHistory(newCostHistory);
                return; // No need to continue if all quantity is covered by purchases
            }
        }

        // If sellingQuantity is greater than the total available quantity, set the cost to 0 or handle it accordingly
        setCostTk(0);
        setCostHistory(newCostHistory);
    };


    const handleRate = (event) => {
        const rate = parseFloat(event?.target?.value);
        const quantity = parseFloat(quantityRef.current.value);
        setDefaultQuantity(quantity)
        setSalesRate(rate);
        setChangingRate(true);
    }

    const closeModal = () => {
        setUpdateProduct(null);
    }

    return (
        <div>
            <input type="checkbox" id="update-modal" class="modal-toggle" />
            <div class="modal">
                <div class="modal-box max-w-none w-full lg:w-11/12 lg:ml-60 relative">
                    <label for="update-modal" onClick={closeModal} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 class="text-lg text-center font-bold">Add Product <span className='text-error'>{addError}</span></h3>
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Product Code</th>
                                <th className='bg-red-600 text-white normal-case'>Product Name</th>
                                <th className='bg-red-600 text-white normal-case'>Quantity</th>
                                <th className='bg-red-600 text-white normal-case'>Rate</th>
                                <th className='bg-red-600 text-white normal-case'>Total</th>
                                <th className='bg-red-600 text-white normal-case'>Action</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                products.map(product => <tr className='hover'>
                                    <td className='text-xs' ref={codeRef}>{product.code}</td>
                                    <td className='text-xs' ref={nameRef}>{(product.name).replace(/\([^\)]+\)\s*$/, '')}</td>
                                    <td><input ref={quantityRef} onChange={handleTotal} type="number" defaultValue={product.quantity} placeholder="Type here" className="input text-xs text-center input-bordered w-28 max-w-lg" /></td>
                                    <td><input ref={rateRef} onChange={handleRate} type="number" defaultValue={product.sell} placeholder="Type here" className="input text-xs text-center input-bordered w-28 max-w-lg" /></td>
                                    <td><input ref={totalRef} type="number" value={total === false ? changingRate === false ? product.sell : salesRate : changingRate === true ? quantity * salesRate : quantity * parseFloat(product?.sell).toFixed(2)} placeholder="Type here" className="input text-xs text-center input-bordered w-40 max-w-lg" /></td>
                                    <td><button disabled={qytError} onClick={addProduct} className={`btn btn-sm`}>Add Product</button> </td>
                                </tr>)
                            }

                        </tbody>
                    </table>

                </div>
            </div >
        </div >
    );
};

export default UpdateModal;