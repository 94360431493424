import React, { useRef } from 'react';
import Barcode from 'react-barcode';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

const PrintBarCode = () => {
    const printingProducts = useSelector((state) => state.purchaseInvoice.printProducts);
    const componentRef = useRef();

    const generatePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Print Barcode`,
    });

    return (
        <div className="bg-gray-100 min-h-screen p-4">
            <div className="text-center mb-4">
                <h1 className="text-2xl font-semibold">Barcode Printing</h1>
            </div>
            <div className="mb-4">
                <div className="flex justify-center">
                    <button className="btn btn-success text-white font-bold" onClick={generatePrint}>Print</button>
                </div>
            </div>
            <div ref={componentRef} className="flex flex-wrap justify-center items-center mt-16">
                {printingProducts.map((product, productIndex) => (
                    Array.from({ length: product.quantity }).map((_, barcodeIndex) => (
                        <div key={`${productIndex}`} className="w-2/12 my-2 mx-2 bg-white shadow-xl">
                            <div style={{marginBottom:'-24px'}} className='text-center text-sm'>{product.name}</div>
                            <div className='flex justify-center px-8'>
                                <Barcode value={product.code} />
                            </div>
                            <div style={{marginTop:'-26px'}} className='text-center text-sm'>{`${product?.remarks} ${product.sell}/-`}</div>
                        </div>
                    ))
                ))}
            </div>
        </div>
    );
};

export default PrintBarCode;


// mport React, { useRef, useState } from 'react';
// import Barcode from 'react-barcode';
// import { useSelector } from 'react-redux';
// import { useReactToPrint } from 'react-to-print';

// const PrintBarCode = () => {
//     const [barcodeValue, setBarcodeValue] = useState('');
//     const [quantity, setQuantity] = useState(1);
//     const printingProducts = useSelector((state) => state.purchaseInvoice.printProducts);
//     const componentRef = useRef();

//     console.log(printingProducts);

//     const products = [
//         { name: "Product A", code: "2023760", quantity: 5 },
//         { name: "Product B", code: "2023761", quantity: 3 },
//         { name: "Product C", code: "2023762", quantity: 2 },
//     ];

//     const handleInputChange = (e) => {
//         setBarcodeValue(e.target.value);
//     };

//     const handleQuantityChange = (e) => {
//         setQuantity(e.target.value);
//     };

//     const generatePrint = useReactToPrint({
//         content: () => componentRef.current,
//         documentTitle: `Print Barcode`,
//     })

//     return (
//         <>
//         {/* // <div className="bg-gray-100 min-h-screen p-4"> */}
//                 {/* <h1 className="text-2xl font-semibold mb-4">Barcode Printing</h1>
//             <div className="mb-4">
//                 <label className="block text-gray-700">Enter Barcode Value:</label>
//                 <input
//                     type="text"
//                     className="border rounded-md p-2 w-full"
//                     value={barcodeValue}
//                     onChange={handleInputChange}
//                 />
//             </div>
//             <div className="mb-4">
//                 <label className="block text-gray-700">Quantity:</label>
//                 <input
//                     type="number"
//                     className="border rounded-md p-2 w-full"
//                     value={quantity}
//                     onChange={handleQuantityChange}
//                 />
//             </div> */}
//                 <div className='w-full mt-16 mx-auto'>
//                 <div className='text-right'>
//                     <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button>
//                 </div>
//                 <div ref={componentRef} className="flex flex-wrap justify-center items-center mt-16">
//                     {printingProducts.map((product, productIndex) => (
//                         Array.from({ length: product.quantity }).map((_, barcodeIndex) => (
//                             <div key={`${productIndex}-${barcodeIndex}`} className="w-2/12 my-2 mx-2 bg-white shadow-xl">
//                                 <div style={{ marginBottom: '-28px' }} className='shadow-2xl bg-white w-full mx-auto'>{product.name}</div>
//                                 <div className='px-8' style={{ display: 'flex', justifyContent: 'center', margin: '0' }}>
//                                     <Barcode className='shadow-xl' value={product.code} />
//                                 </div>
//                                 <div style={{ marginTop: '-28px' }} className='shadow-xl bg-white w-full mx-auto'>{`${product?.remarks} ${product.sell}/-`}</div>
//                             </div>
//                         ))
//                     ))}
//                 </div>
//                 </div>
//         </>
//     );
// };

// export default PrintBarCode;
