import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { editSupplier } from '../../../../features/InfoSlice/infoSlice';
import Loader from '../../../Shared/Loader';



const BankAccountField = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [bankAccounts, setBankAccounts] = useState([]);
    const [updateBankAccount, setUpdateBankAccount] = useState(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        fetch('https://16ana.net/saad_tiles/getbankaccounts')
            .then(res => res.json())
            .then(data => {
                setBankAccounts(data);
                setLoading(false)
            })
    }, [bankAccounts, setBankAccounts, updateBankAccount, setUpdateBankAccount, dispatch])

    if (loading) {
        return <Loader />
    }

    const onSubmit = data => {
        const user = userAc?.email.split('@')[0];
        fetch('https://16ana.net/saad_tiles/addbankaccount', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({user,...data}),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Bank Account Added')
                reset();
            })

    }

    const handleDeleteBankAccount = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://16ana.net/saad_tiles/getbankaccount/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdateBankAccount(null);
                })
        }
    };

    const handleUpdateBankAccount = (bankAccount) => {

        // fetch(`https://16ana.net/saad_tiles/getbankAccounts/${Bank Account._id}`)
        //     .then(res => res.json())
        //     .then(data => {
        //         dispatch(editSupplier(data))
        //     })
        setUpdateBankAccount(bankAccount);
    }

    const handleApprove=(bankAccount)=>{
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://16ana.net/saad_tiles/getbankaccount/${bankAccount._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({approvedBy})
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Bank Account Approved');
                // window.location.reload(false)
            })
    }
    return (
        <div className='shadow-2xl px-2 pb-5 my-5 text-xs'>
            <form onSubmit={handleSubmit(onSubmit)} className='container grid grid-cols-1 lg:grid-cols-3 gap-1 justify-center items-center'>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Bank Account Name</span>
                    </label>
                    <input {...register("name")} type="text" placeholder="Type Bank Account Name Here" class="rounded-lg text-xs bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Bank Account No</span>
                    </label>
                    <input {...register("accountno")} type="text" placeholder="Type Account No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Opening Balance</span>
                    </label>
                    <input {...register("opening")} type="number" placeholder="Opening Balance" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Status</span>
                    </label>
                    <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                        <option selected>Active</option>
                        <option>Inactive</option>
                    </select>
                </div>
                <input disabled={!userAc?.addBankAccount} type="submit" value='Add Bank Account' className='btn w-full lg:w-60 bg-red-600 text-white mt-2 lg:mt-9' />
            </form>

            {
                bankAccounts.length ? <div class="overflow-x-auto mt-8">
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL</th>
                                <th className='bg-red-600 text-white normal-case'>Bank Account Name</th>
                                <th className='bg-red-600 text-white normal-case'>Account No</th>
                                <th className='bg-red-600 text-white normal-case'>Opening</th>
                                <th className='bg-red-600 text-white normal-case'>Status</th>
                                <th className='bg-red-600 text-white normal-case'>Added By</th>
                                <th className='bg-red-600 text-white normal-case'>Approved By</th>
                                <th className='bg-red-600 text-white normal-case'>Edit</th>
                                <th className='bg-red-600 text-white normal-case'>Delete</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                bankAccounts.map((bankAccount, index) => <tr className='hover'>
                                    <td>{index + 1}</td>
                                    <td>{bankAccount.name}</td>
                                    <td>{bankAccount.accountno}</td>
                                    <td>{bankAccount.opening}</td>
                                    <td> <button className={`btn btn-xs normal-case ${bankAccount.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{bankAccount?.status}</button> </td>
                                    <td><button className='btn btn-xs btn-primary text-white normal-case'>{bankAccount?.user ? bankAccount.user : "User"}</button></td>
                                    {userAc.approvalBankAccount? <td>{bankAccount?.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{bankAccount?.approvedBy}</button>: <button onClick={()=>handleApprove(bankAccount)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td>:
                                   <td>{bankAccount.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{bankAccount.approvedBy}</button>:<button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                    {userAc?.editBankAccount && <td><button><label for="update-modal" onClick={() => handleUpdateBankAccount(bankAccount)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                    {userAc?.deleteBankAccount && <td><button onClick={() => handleDeleteBankAccount(bankAccount._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div> : ""
            }
            {
                updateBankAccount && <UpdateModal setUpdateBankAccount={setUpdateBankAccount} updateBankAccount={updateBankAccount}></UpdateModal>
            }
        </div>
    );
};

export default BankAccountField;