import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    products: [],
    updatePurchaseOrder: [],
}

const purchaseOrderSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        getProducts: (state, action) => {
            state.products = action.payload
        },
        updatePurchaseOrder: (state, action) => {
            state.updatePurchaseOrder = action.payload;
        },
    }
});

export const { getProducts, updatePurchaseOrder } = purchaseOrderSlice.actions;
export default purchaseOrderSlice.reducer;