import { configureStore } from "@reduxjs/toolkit";
import bookingSlice from "../features/bookingSlice/bookingSlice";
import serviceReportSlice from "../features/serviceReportSlice/serviceReportSlice";
import infoSlice from "../features/InfoSlice/infoSlice";
import PurchaseSlice from "../features/PurchaseSlice/PurchaseSlice";
import SalesSlice from "../features/SalesSlice/SalesSlice";
import userSlice from "../features/userSlice/userSlice";
import purchaseOrderSlice from "../features/purchaseOrderSlice/purchaseOrderSlice";
import api from "../features/api/apiSlice";



const store = configureStore({
    reducer: {
        booking: bookingSlice,
        serviceReport: serviceReportSlice,
        info: infoSlice,
        purchaseInvoice: PurchaseSlice,
        salesInvoice: SalesSlice,
        user: userSlice,
        purchaseOrder:purchaseOrderSlice,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
})

export default store;