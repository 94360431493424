import React, { useEffect, useState } from 'react';
import CategoryField from './CategoryField';


const AddCategory = () => {
     return (
        <div className='mx-auto w-full p-2 mt-8'>
            <h1 className='text-md text-center bg-red-600 p-3 text-white rounded-xl'>Add A New Category Name</h1>
            <CategoryField></CategoryField>
        </div>
    );
};

export default AddCategory;