import { useState, useEffect } from 'react';

const usePaginationSearchForStock = (url) => {
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState();
    const [limit, setLimit] = useState(20);
    const [pageNo, setPageNo] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);
    const [totalItem, setTotalItem] = useState();
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        fetch(`${url}?search=${searchText}&category=${selectedCategory}&page=${pageNo}&limit=${limit}`)
            .then(res => res.json())
            .then(data => {
                setItems(data.total);
                setPageCount(Math.ceil(data.document / limit));
                setLoading(false);
                setTotalItem(data.myLength);
            });
    }, [limit, selectedCategory, pageNo, searchText]);

    const handleSearchResult = (event) => {
        const searchText = event.target.value;
        fetch(`${url}?search=${searchText}&category=${selectedCategory}&page=1&limit=${limit}`)
            .then(res => res.json())
            .then(data => {
                setItems(data.total);
                if (searchText.length) {
                    setPageCount(Math.ceil(data.total.length / limit));
                } else {
                    setPageCount(Math.ceil(data.document / limit));
                }
                setLoading(false);
            });
    };

    const handleLimit = (event) => {
        const limited = event.target.value === "All" ? "All" : parseInt(event.target.value);
        setLimit(limited);
    };

    const handlePageClick = (data) => {
        fetch(`${url}?search=${searchText}&category=${selectedCategory}&page=${data.selected + 1}&limit=${limit}`)
            .then(res => res.json())
            .then(data => {
                setItems(data.total);
                setPageCount(Math.ceil(data.document / limit));
                setLoading(false);
            });
        setPageNo(data.selected + 1);
    };

    const refetchData = (newPage) => {
        setPageNo(newPage ? newPage : 1);
        setLoading(true);

        fetch(`${url}?search=${searchText}&category=${selectedCategory}&page=${newPage ? newPage : 1}&limit=${limit}`)
            .then((res) => res.json())
            .then((data) => {
                setItems(data.total);
                setPageCount(Math.ceil(data.document / limit));
                setLoading(false);
            });
    };

    const handleCategoryChange = (event) => {
        const newCategory = event.target.value;
        setSelectedCategory(newCategory);
        refetchData(); // Fetch data with the new category
    };

    return {
        items,
        pageCount,
        totalItem,
        limit,
        pageNo,
        searchText,
        loading,
        selectedCategory,
        handleSearchResult,
        handleLimit,
        handlePageClick,
        refetchData,
        handleCategoryChange,
    };
};

export default usePaginationSearchForStock;
