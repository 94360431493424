import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../../Firebase/Firebase';
import BookingTable from './BookingTable';
import UpdateModal from './UpdateModal';

const BookingList = () => {
const [bookings, setBookings]= useState([]);
const [updateBooking, setUpdateBooking]= useState(null);

// console.log();

    useEffect(() => {
        fetch(`https://16ana.net/saad_tiles/getbooking`)
            .then(res => res.json())
            .then(data => {
                const filter= data.filter(book=>!book?.technician)
                setBookings(filter);
            })
    }, [bookings])

    return (
        <div className='mt-8'>
            <h4 className='text-2xl text-red-600 font-bold text-center'>Pending Bookings ({bookings.length}pcs)</h4>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 my-8'>
                {
                    bookings.map(booking=><BookingTable
                    key={booking.id}
                    booking={booking}
                    setUpdateBooking={setUpdateBooking}
                    />)
                }
            </div>
            {
                updateBooking && <UpdateModal setUpdateBooking={setUpdateBooking} updateBooking={updateBooking}></UpdateModal>
            }
        </div>
    );
};

export default BookingList;