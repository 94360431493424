import logo from '../../assets/images/logo.jpg'

const BusinessInfo = {
    name: "Saad Tiles",
    fullName: "M/S Saad Tiles and Sanitary House",
    dealer: "(Authorized Dealer Of Hua Thai Ceramics and RAK(B))",
    address: "Near RAK Ceramics, Sreepur, Gazipur",
    mobile: "Mobile: 01971-185475,01774-039641",
    img: logo,
}

export default BusinessInfo;
